@import './variables.scss';

@media print {
  body {
    color-adjust: exact;
  }
}

.castration-status {

  &.requested,
  &.correction,
  &.revision,
  &.corrected,
  &.scheduled,
  &.preApproved {
    color: $primary-color;
  }

  &.canceled,
  &.rejected {
    color: $error-color;
  }

  &.attendance,
  &.awaitingConfirmation,
  &.inTreatment {
    color: blueviolet;
  }

  &.completed,
  &.approved {
    color: $success-color;
  }
}

.mistreatment-status {
  &.opened,
  &.checking {
    color: $primary-color;
  }

  &.done {
    color: $success-color;
  }

  &.canceled,
  &.rejected {
    color: $error-color;
  }
}

.mistreatment-result {
  &.awaiting {
    color: $primary-color;
  }
  &.measuresApplied {
    color: $info-color;
  }
  &.canceled {
    color: $error-color;
  }
}

.batch-status {
  &.requested {
    color: $primary-color;
  }

  &.rejected {
    color: $error-color;
  }

  &.approved {
    color: $success-color;
  }
}

.adoption-status {
  &.requested {
    color: $info-color;
  }

  &.confirmed {
    color: $success-color;
  }

  &.rejected, &.canceled {
    color: $error-color;
  }
}

.clinical-care-status {
  &.medicalRelease,
  &.pullout,
  &.done,
  &.caught {
    color: $success-color;
  }

  &.forwarded,
  &.hospitalized{
    color: $primary-color;
  }

  &.canceled {
    color: $error-color;
  }

  &.screening {
    color: blueviolet;
  }
}

.no-decoration:hover {
  text-decoration: none;
  cursor: pointer;
}

.no-decoration:hover .decorated {
  text-decoration: underline;
}

.sticky-navbar {
  position: sticky;
  top: 0;
  z-index: 9;
  background-color: #fff;
}

.navbar .btn.btn-link.active {
  pointer-events: none;
  background-color: #eee;
  border: 1px solid $secondary-color;
}

.card,
.panel {
  border: 0;
  box-shadow: 0 0.25rem 1rem rgba(48, 55, 66, .15);
  height: 100%;
}

.card.custom {
  border: 0.05rem solid #dadee4;
  border-radius: 0.1rem;
  box-shadow: none;
}

.justify-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: flex-end;
}

.full-vh {
  min-height: 100vh;
}

.full-header-height {
  min-height: calc(100vh - 36px - 16px);
}

.pre {
  white-space: pre-wrap;
}

.height-100 {
  height: 100%;
}

.flex-0 {
  flex: 0;
}

.flex-1 {
  flex: 1;
}

.only-print {
  display: none;
}

@media print {
  .no-print {
    display: none;
  }

  .only-print {
    display: block;
  }

  .inside-avoid {
    break-inside: avoid;
  }
}

@media print {
  .pagebreak { page-break-before: always; } /* page-break-after works, as well */
}