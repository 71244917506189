@import 'variables';

$border-radius: 10px;

.rga-box {
  width: 350px;
  height: 280px;
  border-radius: $border-radius;
  background-color: #f28914;
  overflow: hidden;
  position: relative;
  padding: 20px;
}

.rga-box-body {
  background-image: url("../../../public/images/marca50.png"); 
  background-size: 100% 100%;
  background-color: #faddbd;
  width: 100%;
  height: 100%;
  border-radius: $border-radius;
  display: flex;
  flex-direction: column;
  z-index: -1;
}

.rga-brand-wrapper {
  left: 0;
  right: 0;
  text-align: center;
  position: absolute;
  display: flex;
  height: 20px;
  align-items: center;
  justify-content: center;
}

.rga-paws-wrapper {
  position: absolute;
  display: flex;
}

.rga-brand {
  border-radius: 4px;
  font-size: 8px;
  padding: 1px 3px;
  display: inline;
  background-color: #fff;
  color: #f28914;
  font-weight: bold;
  z-index: 1;
}

.rga-brand img {
  width: 6px;
  margin-right: 1px;
}

.rga-paws {
  color: #fff;
  opacity: 0.5;
  width: 100%;
  height: 40px;
}

.card-rga {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 0.25rem;
}

.text-rga {
  color: #362B21;
  font-size: 100%;
}

.logo-petis {
  display: flex;
  align-items: center;
}

.full-header-height {
  min-height: calc(90vh - 36px - 16px);
}

.signature {
  max-width: 1000px;
  margin: 0 auto;
  padding: 30px;
}

@media print {
  .card-rga {
    box-shadow: none;
  }    

  .rga-box {
    border-radius: 0;
  }
}
